:root {
    --text-color: white;
    --text-font: Inter, sans-serif;
}

.preview-image {
    margin-right: 20px;
    border-radius: 15px;
    background-color: #5C5C5C;
}

.preview-image-mobile {
    border-radius: 15px;
    background-color: #5C5C5C;
}

.load-preview {
    display: none;
}

.steam-name {
    text-align: left;
    margin: 0;
    color: var(--text-color);
    font-family: var(--text-font);
}

.steam-price {
    text-align: right;
    margin: 0 20px 0 0;
    color: var(--text-color);
    font-family: var(--text-font);
}

.preview-text {
    text-align: left;
    margin: 5px;
    font-size: 14px;
    color: var(--text-color);
    font-family: var(--text-font);
}
