.title-container {
    display: flex;
    align-items: center;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 0 10px;
}

.link {
    color: white;
    &:hover {
        color: lightgrey;
        cursor: pointer;
    }
}

