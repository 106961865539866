.App {
  background-color: #212121;
  overflow: auto;
  height: 100vh;
}

.loading-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #212121;
  display: flex;
}

.left-component {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;
}

.right-component {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
}

.search-bar-container {
  flex: 2;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  height: 80vh;
}

.search-bar-container-mobile {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  height: 80vh;
}

.results-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  min-width: 200px;
  text-align: center;
  height: 80vh;
}

.search-bar {
  display: flex;
  width: 100%;
  align-items: center;
}

.menu-icons {
  display: flex;
  align-items: center;
}

.menu-icons-mobile {
  margin-left: 10px;
}

.mobile-button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000000;
  color: #fff;
  transition: background-color 0.3s ease;
}

.mobile-button:focus {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000000;
  color: #fff;
  transition: background-color 0.3s ease;
}

.menu-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(51, 51, 51);
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  min-width: 200px;
  text-align: left;
}

.menu-items.show {
  display: block;
}

.error-container {
  display: flex;
  align-items: center;
}

.welcome-message, .error-message {
  color: white;
  font-family: Inter, sans-serif;
  margin: 0 auto;
  font-weight: normal;
  font-size: 1.2em;
  padding-bottom: 30px;
  @media only screen and (min-width: 1024px) {
    max-width: 700px;
  }
  @media only screen and (min-width: 768px) {
    max-width: 500px;
  }
}
