.input-wrapper {
    width: 100%;
    margin: 20px 8% 10px 0;
    @media only screen and (max-width: 767px) {
        margin: 20px 0 10px;
    }
}

.search-box {
    background-color: #2f2f2f;
    border-radius: 50px;
    display: flex;
    align-items: center;
    width: '100%';
}

.form-control {
    border: none;
    height: 100%;
    width: 100%;
    margin-left: 5px;
    color: white;
}

.form-control:focus {
    outline: none;
}
